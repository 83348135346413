#who-content {
    .desc {
        .btn.btn-home {
            background: $primary;
            width: 150px;
            height: 58px;
            border-radius: 27px;
            color: #fff;
        }
    }
}
