$primary : #145068;
$secondary : #E8F1F4;
$tercary : #6edbdd;

$info : #E8F1F4; //background

$danger : #ff4400;

// Circle Colors
$circleAccompany : #ffff6b;
$circleSupport : rgb(0, 254, 241);
$circlePromote : rgb(180, 60, 168);