#test-container {
    height: auto;
    width: 100%;
    overflow: hidden;
    flex: 1;

    a {
        text-decoration: none;
    }

    .position {
        position: relative;

        .ovale {
            height: 581px;
            width: 717px;
            border-radius: 50% / 50%;
            z-index: 2;
            rotate: -45deg;
            position: absolute;
            top: 100;
            top: -31px;
            left: -34px;
            background-color: #6edbdd;
        }

        .circle {
            height: 200px;
            width: 200px;
            border-radius: 50%;
            position: absolute;
            top: 91px;
            right: -54px;
            background-color: black;
            opacity: 0.1;
        }
    }

    #test {
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        .test-content {
            display: flex;

            .img-outil {
                width: 48%;
                height: 100%;
                object-fit: cover;
                z-index: 4;
                position: relative;
                bottom: -99px;
                max-width: 100%;
                transform: translate(0px, 0px);
            }

            .test-paragraphe {
                margin-left: 57%;
                margin-top: 3%;
                z-index: 2;

                h2 {
                    font-weight: bold;
                    margin-bottom: 4%;
                }

                p {
                    font-weight: 600;
                    margin-bottom: 2rem;
                }

                .btn {
                    width: 153px;
                    height: 68px;
                    border-radius: 34px;
                    color: #fff;
                    font-weight: bold;
                    margin-left: 26%;
                }
            }
        }
    }
}

@media screen and (min-width: 300px) {
    #test-container {
        .position {
            .ovale {
                height: 581px;
                width: 717px;
                border-radius: 50%/50%;
                z-index: 2;
                rotate: -45deg;
                position: absolute;
                top: 100;
                top: 479px;
                left: -419px;
            }
        }

        #test {
            .test-content {
                .img-outil {
                    width: 87%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 4;
                    position: relative;
                    bottom: -506px;
                }

                .test-paragraphe {
                    margin-left: -81%;
                    margin-top: 32%;
                    z-index: 2;

                    .btn {
                        width: 153px;
                        height: 68px;
                        border-radius: 34px;
                        background: #1c709b;
                        color: #fff;
                        font-weight: bold;
                        margin-left: 13%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 320px) {
    #test-container {
        .position {
            .ovale {
                height: 581px;
                width: 638px;
                border-radius: 50%/50%;
                z-index: 2;
                rotate: -45deg;
                position: absolute;
                top: 100;
                top: 407px;
                left: -353px;
            }
        }

        #test {
            .test-content {
                .img-outil {
                    width: 95%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 4;
                    position: relative;
                    bottom: -476px;
                }

                .test-paragraphe {
                    margin-right: 22%;
                    margin-top: 27%;
                    z-index: 2;
                }
            }
        }
    }
}

@media screen and (min-width: 375px) {
    #test-container {
        .position {
            .ovale {
                height: 581px;
                width: 717px;
                border-radius: 50%/50%;
                z-index: 2;
                rotate: -45deg;
                position: absolute;
                top: 100;
                top: 432px;
                left: -358px;
            }
        }

        #test {
            .test-content {
                .img-outil {
                    width: 90%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 4;
                    position: relative;
                    bottom: -449px;
                }

                .test-paragraphe {
                    margin-right: 1%;
                    margin-top: 29%;
                    z-index: 2;

                    .btn {
                        width: 153px;
                        height: 68px;
                        border-radius: 34px;
                        background: #1c709b;
                        color: #fff;
                        font-weight: bold;
                        margin-left: 24%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 576px) {
    #test-container {
        .position {
            .ovale {
                height: 581px;
                width: 717px;
                border-radius: 50%/50%;
                z-index: 2;
                rotate: -45deg;
                position: absolute;
                top: 100;
                top: 388px;
                left: -244px;
            }
        }

        #test {
            .test-content {
                .img-outil {
                    width: 76%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 4;
                    position: relative;
                    bottom: -371px;
                }

                .test-paragraphe {
                    margin-left: -55%;
                    margin-top: 11%;
                    z-index: 2;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    #test-container {
        .position {
            .ovale {
                height: 581px;
                width: 717px;
                border-radius: 50%/50%;
                z-index: 2;
                rotate: -45deg;
                position: absolute;
                top: 100;
                top: 316px;
                left: -166px;
            }
        }

        #test {
            .test-content {
                .img-outil {
                    width: 62%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 4;
                    position: relative;
                    bottom: -302px;
                }

                .test-paragraphe {
                    margin-left: -32%;
                    margin-top: 5%;
                    z-index: 2;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    #test-container {
        height: auto;
        width: 100%;
        overflow: hidden;
        flex: 1;

        a {
            text-decoration: none;
        }

        .position {
            position: relative;

            .ovale {
                height: 526px;
                width: 654px;
                border-radius: 50%/50%;
                z-index: 2;
                rotate: -45deg;
                position: absolute;
                top: 100;
                top: 22px;
                left: -40px;
                background-color: #6edbdd;
            }

            .circle {
                height: 200px;
                width: 200px;
                border-radius: 50%;
                position: absolute;
                top: 91px;
                right: -54px;
                background-color: black;
                opacity: 0.1;
            }
        }

        #test {
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            .test-content {
                display: flex;

                .img-outil {
                    width: 43%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 4;
                    position: relative;
                    bottom: 5px;
                }

                .test-paragraphe {
                    margin-left: 10%;
                    margin-top: 3%;
                    z-index: 2;

                    h2 {
                        font-weight: bold;
                        margin-bottom: 4%;
                    }

                    p {
                        font-weight: 600;
                        margin-bottom: 2rem;
                    }

                    .btn {
                        width: 153px;
                        height: 68px;
                        border-radius: 34px;
                        background: #1c709b;
                        color: #fff;
                        font-weight: bold;
                        margin-left: 26%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1880px) {
    #test-container {
        .position {
            .ovale {
                height: 782px;
                width: 996px;
                border-radius: 50%/50%;
                z-index: 2;
                rotate: -45deg;
                position: absolute;
                top: 100;
                top: 71px;
                left: -109px;
            }
        }

        #test {
            .test-content {
                .img-outil {
                    width: 48%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 4;
                    position: relative;
                    bottom: -14px;
                }

                .test-paragraphe {
                    margin-left: 10%;
                    margin-top: 7%;
                    z-index: 2;
                }
            }
        }
    }
}
