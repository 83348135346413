@media screen and (min-width: 300px) {
    .searchbar {
        width: 367%;
        max-width: 375px;
        margin-left: -7.4em;
        padding: 10px 20px;
    }
}
@media screen and (min-width: 320px) {
    .searchbar {
        width: 352%;
        max-width: 375px;
        margin-left: -7.4em;
        padding: 10px 20px;
    }
}
@media screen and (min-width: 375px) {
    .searchbar {
        width: 270%;
        max-width: 375px;
        margin-left: -6.4em;
        padding: 10px 20px;
    }
}
@media screen and (min-width: 576px) {
    .searchbar {
        width: 176%;
        max-width: 576px;
        margin-left: -6em;
        padding: 10px 20px;
    }
}
@media screen and (min-width: 768px) {
    .searchbar {
        width: 126%;
        max-width: 768px;
        margin-left: -2em;
        padding: 10px 20px;
    }
}
@media screen and (min-width: 1024px) {
    .searchbar {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
        padding: 10px 20px;
    }
}
@media screen and (min-width: 1440px) {
    .searchbar {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 10px 20px;
    }
}
