.burger {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 32px;
  height: 24px;
  cursor: pointer;
  z-index: 2;
}

.burger .line {
  width: 39px;
  height: 3px;
  background-color: #0a2632;
  margin: 0;
  transition: all 0.2s ease-in-out;
}

.burger.active .line:nth-child(2) {
  opacity: 0;
}

.burger.active .line:nth-child(1) {
  transform: rotate(45deg) translateY(13px);
}

.burger.active .line:nth-child(3) {
  transform: rotate(-45deg) translateY(-16px);
}


.menu {
  display: none !important;
}

.menu-active {
  display: flex !important;
  flex-direction: column;
  justify-content: start;
  animation: fadeIn 0.5s ease-in-out forwards;
}

