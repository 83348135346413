*{
    font-family: 'Montserrat', sans-serif;
}

html, body{
    height: 100%;
    
}

body{
    display: flex;
    flex-direction: column;
    margin: 0;
}

form {
    ul {
        li {
            color: red;
        }
    }
    label {
        font-size: 14px;
    }
}

.cursor {
    cursor: pointer;
}

.margin-bottom {
    margin-bottom: -69px;
}

@media (min-width: 1440px) {
    .margin-bottom {
        margin-bottom: -69px;
    }
}

p{
    font-size:14px !important;
}

// BUTTONS

a.btn{
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (min-width: 1200px) {
    a.btn{
        font-size: 14px;
    }
}

